import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import Estrutura from "../components/Estrutura";

const PrivateRoute = () => {
  const user = useAuth();
  if (!user.token) return <Navigate to="/login" />;
  return (
    <Estrutura>
      <Outlet />
    </Estrutura>
  );
};

export default PrivateRoute;
