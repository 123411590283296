import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    '23 Abr, 2024',
    'Francisco Krieser',
    'ProntoLead',
    'Tecnologia',
    75,
  ),
  createData(
    1,
    '23 Abr, 2024',
    'Filipe Casagrande',
    'Instituto Caldeira',
    'Hub de inovação',
    34,
  ),
  createData(2, '22 Abr, 2024', 'Paulo Krieser', 'Econodata', 'Tecnologia', 42),
  createData(3, '21 Abr, 2024', 'Maria Fernandez', 'Super Marketing', 'Agência de Marketing', 23),
  createData(3, '21 Abr, 2024', 'Luiz Souza', 'Tudo Já', 'E-commerce', 27),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function ResumoLeads() {
  return (
    <React.Fragment>
      <Title>Leads Qualificados</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Empresa</TableCell>
            <TableCell>Atividade</TableCell>
            <TableCell align="right">Funcionários</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.shipTo}</TableCell>
              <TableCell>{row.paymentMethod}</TableCell>
              <TableCell align="right">{`${row.amount}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        Ver mais
      </Link>
    </React.Fragment>
  );
}