import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import SignIn from "./components/SignIn";
import LeadsTable from "./components/LeadsTable";
import Leads from "./components/Leads";
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./router/route";
import Painel from "./components/Painel";
import LeadMessages from "./components/LeadMessages";
import OAuth2Callback from "./components/OAuth2Callback";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/test" element={<div>Hello World</div>} />
            <Route path="/oauth2callback" element={<OAuth2Callback />} />
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Painel />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/leads" element={<Leads />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/leads/:leadId/messages" element={<LeadMessages />} />
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
