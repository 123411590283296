import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import GraficoConversas from "./GraficoConversas";
import ResumoAgendamentos from "./ResumoAgendamentos";
import ResumoLeads from "./ResumoLeads";

export default function Painel() {
  return (
    <Grid container spacing={3}>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          <GraficoConversas />
        </Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          <ResumoAgendamentos />
        </Paper>
      </Grid>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <ResumoLeads />
        </Paper>
      </Grid>
    </Grid>
  );
}
