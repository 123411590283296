import * as React from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import axios from "axios";
import { useEffect, useState } from "react";

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    "23 Abr, 2024",
    "Francisco Krieser",
    "ProntoLead",
    "Tecnologia",
    75
  ),
  createData(
    1,
    "23 Abr, 2024",
    "Filipe Casagrande",
    "Instituto Caldeira",
    "Hub de inovação",
    34
  ),
  createData(2, "22 Abr, 2024", "Paulo Krieser", "Econodata", "Tecnologia", 42),
  createData(
    3,
    "21 Abr, 2024",
    "Maria Fernandez",
    "Super Marketing",
    "Agência de Marketing",
    23
  ),
  createData(3, "21 Abr, 2024", "Luiz Souza", "Tudo Já", "E-commerce", 27),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Leads() {
  const navigate = useNavigate();
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds
  
  const handleDetailsClick = (route) => (e) => {
    e.preventDefault();
    navigate(route);
    return;
  };

  const [leadViewData, setLeadViewData] = useState({
    leadParams: [],
    leads: [],
  });
  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        `${process.env.REACT_APP_PRONTOPLAY_API}/leadView`,
        { headers: { Authorization: localStorage.getItem("token") } }
      );
      setLeadViewData(result.data);
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <React.Fragment>
            <Title>Leads</Title>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Status</TableCell>
                  {leadViewData.leadParams.map((param) => (
                    <TableCell>{param.label}</TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leadViewData.leads.map((lead) => (
                  <TableRow key={lead.id}>
                    <TableCell>{lead.name}</TableCell>
                    <TableCell>{lead.status}</TableCell>
                    {leadViewData.leadParams.map((param) => (
                      <TableCell>{lead.params[param.key]}</TableCell>
                    ))}
                    <TableCell><Link color="primary" sx={{ mt: 3 }} href="#"  onClick={handleDetailsClick(`/leads/${lead.id}/messages`)}>Mensages</Link></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <Link
              color="primary"
              href="#"
              onClick={preventDefault}
              sx={{ mt: 3 }}
            >
              Ver mais
            </Link> */}
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
