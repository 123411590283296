import * as React from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from 'react-router';

export default function LeadMessages() {
  const POLLING_INTERVAL = 5000; // Poll every 5 seconds

  const [leadMessagesData, setLeadMessagesData] = useState({
    agent: {},
    lead: {},
    leadMessages: [],
  });
  const { leadId } = useParams();
  useEffect(() => {
    async function fetchData() {
      
      const result = await axios(
        `${process.env.REACT_APP_PRONTOPLAY_API}/leads/${leadId}/messages`,
        { headers: { Authorization: localStorage.getItem("token") } }
      );
      setLeadMessagesData(result.data);
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const formatSenderName = (leadMessage) => {
    return leadMessage.type === "assistant"
      ? leadMessagesData.agent.name
      : leadMessagesData.lead.name;
  };
  const formatStatus = (leadMessage) => {
    return leadMessage.type === "assistant"
      ? leadMessage.wsStatus === "SENT"
        ? "Enviado"
        : "Aguardando envio"
      : "Recebido";
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <React.Fragment>
            <Title>Mensagens do Lead - {leadMessagesData.lead.name}</Title>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Remetente</TableCell>
                  <TableCell>Mensagem</TableCell>
                  <TableCell>Data/Hora</TableCell>
                  <TableCell>Situação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leadMessagesData.leadMessages.map((leadMessage) => (
                  <TableRow key={leadMessage.id}>
                    <TableCell>{formatSenderName(leadMessage)}</TableCell>
                    <TableCell>{leadMessage.content}</TableCell>
                    <TableCell>{leadMessage.updatedAt}</TableCell>
                    <TableCell>{formatStatus(leadMessage)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <Link
              color="primary"
              href="#"
              onClick={preventDefault}
              sx={{ mt: 3 }}
            >
              Ver mais
            </Link> */}
          </React.Fragment>
        </Paper>
      </Grid>
    </Grid>
  );
}
