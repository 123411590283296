import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider";
import { useSearchParams } from "react-router-dom";

export default function OAuth2Callback() {
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      const code = searchParams.get("code");
      auth.loginAction({ code });
    }
    fetchData();

    return;
  }, [auth, searchParams]);

  return (
    <div></div>
  );
}
